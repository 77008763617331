import React from "react";

import Fieldset from "lib/components/Fieldset/Fieldset";
import styles from "../Membership.module.scss";

const ParentChoice = ({ scrollToAddMember, errors, handleAddingParent }) => (
  <Fieldset
    legend="Parental Details"
    description="It is mandatory to have a parent/guardian associated with a child membership. Please provide a contact."
  >
    <div className={styles.parentOrReceipt}>
      <h3>EITHER</h3>
      <p>(1) Add an adult member to the membership above.</p>
      <a onClick={scrollToAddMember} className={"btn btn-primary"}>
        Add Parent Member
      </a>
      <h3>OR</h3>
      <p>(2) Add a non-member parent.</p>
      <a
        onClick={() => handleAddingParent(false)}
        className={"btn btn-primary"}
      >
        Add Parent (Non-Member)
      </a>
    </div>
    {errors["payee_id"] && errors["payee_id"].length > 0 && (
      <p className={styles.baseError}>
        adult member or parent details must be present.
      </p>
    )}
  </Fieldset>
);

export default ParentChoice;
