import React from "react";

import Fieldset from "lib/components/Fieldset/Fieldset";
import styles from "../Membership.module.scss";

const AddParentMember = ({ scrollToAddMember, errors }) => (
  <Fieldset
    legend="Parent Required"
    description="It is mandatory to add a parent/guardian member with a child membership."
  >
    <div className={styles.parentOrReceipt}>
      <p>Add an adult member to the membership above.</p>
      <a onClick={scrollToAddMember} className={"btn btn-primary"}>
        Add Parent Member
      </a>
    </div>
    {errors["payee_id"] && errors["payee_id"].length > 0 && (
      <p className={styles.baseError}>adult member must be present.</p>
    )}
  </Fieldset>
);

export default AddParentMember;
