import React from "react";

import Fieldset from "../../../lib/components/Fieldset/Fieldset";
import LottoEntryField from "./LottoEntryField";

export type LottoMetaTypes = {
  draw_type: string;
  draw_name: string;
  club_id: number;
  highest_number: number;
  quantity_drawn: number;
};

interface DrawProduct {
  entry_count: number;
  description: string;
  membership_bundles_use_product_description: boolean;
}

interface DrawEntry {
  numbers: (string | number)[];
  id: number;
}

interface LottoEntriesFormProps {
  drawProduct: DrawProduct;
  lottoMeta: LottoMetaTypes;
  drawEntries: DrawEntry[];
  handleEntriesChanged: (index: number, entry: DrawEntry) => void;
  errors: { [key: string]: { numbers: string[] } };
}

interface NonNumberEntriesProps {
  lottoMeta: LottoMetaTypes;
  drawProduct: DrawProduct;
}

interface LottoNumbersSectionProps extends LottoEntriesFormProps {}

const getEntryDescription = (drawProduct: DrawProduct): string =>
  drawProduct.membership_bundles_use_product_description
    ? drawProduct.description
    : "Please select your numbers. Entries will be associated with the name and email address of the person paying.";

const getErrorMessage = (
  errors: { [key: string]: { numbers: string[] } },
  index: number,
  entry?: DrawEntry
): string => {
  if (!entry) return "";
  const key = entry.id || index;
  return errors?.[key]?.numbers?.join(", ") || "";
};

const NonNumberEntries: React.FC<NonNumberEntriesProps> = ({
  lottoMeta,
  drawProduct,
}) => (
  <div className="member_fields">
    <Fieldset
      legend={lottoMeta.draw_name}
      description={getEntryDescription(drawProduct)}
    >
      {Array.from({ length: drawProduct.entry_count }).map((_, index) => (
        <div key={`entry-${index}`} className="form-group string">
          <label className="control-label select">Entry {index + 1}</label>
          <div>
            <p>This entitles you to 1 entry in the {lottoMeta.draw_name}</p>
          </div>
        </div>
      ))}
    </Fieldset>
  </div>
);

const LottoNumbersSection: React.FC<LottoNumbersSectionProps> = ({
  drawProduct,
  lottoMeta,
  drawEntries,
  handleEntriesChanged,
  errors,
}) => (
  <div className="member_fields">
    <Fieldset
      legend={lottoMeta.draw_name}
      description={getEntryDescription(drawProduct)}
    >
      {Array.from({ length: drawProduct.entry_count }).map((_, index) => (
        <div key={`entry-${index}`}>
          <LottoEntryField
            lottoMeta={lottoMeta}
            numbers={drawEntries[index]?.numbers || []}
            label={
              drawProduct.entry_count > 1
                ? `Entry ${index + 1} - Select numbers from 1 to ${
                    lottoMeta.highest_number
                  }`
                : `Select numbers from 1 to ${lottoMeta.highest_number}`
            }
            handleOnChange={(numbers) => {
              handleEntriesChanged(index, {
                ...drawEntries[index],
                numbers,
              });
            }}
            error={getErrorMessage(errors, index, drawEntries[index])}
          />
        </div>
      ))}
    </Fieldset>
  </div>
);

const LottoEntriesForm: React.FC<LottoEntriesFormProps> = ({
  drawProduct,
  lottoMeta,
  drawEntries,
  handleEntriesChanged,
  errors,
}) => {
  return lottoMeta.draw_type === "lotto" ? (
    <LottoNumbersSection
      drawProduct={drawProduct}
      lottoMeta={lottoMeta}
      drawEntries={drawEntries}
      handleEntriesChanged={handleEntriesChanged}
      errors={errors}
    />
  ) : (
    <NonNumberEntries lottoMeta={lottoMeta} drawProduct={drawProduct} />
  );
};

export default LottoEntriesForm;
