import React from "react";

import Fieldset from "lib/components/Fieldset/Fieldset";
import { DynamicField } from "../FormInputs";

const Parent = ({
  showSwitchLink,
  membershipProduct,
  membership,
  errors,
  handleValueChange,
  handleCustomValueChange,
  handleRemoveParent,
}) => {
  return (
    <Fieldset
      legend="Parent / Guardian Details"
      description={`${
        membershipProduct.clubId != 2323
          ? "If not adding any adult members, p"
          : "P"
      }lease provide parent details.`}
    >
      {showSwitchLink && (
        <p>
          Or click <a onClick={handleRemoveParent}>here</a> to add an adult
          member to your membership purchase instead.
        </p>
      )}
      <DynamicField
        name="parent_forename"
        label="Parent/guardian first name"
        value={membership["parent_forename"]}
        error={errors["parent_forename"] ? errors["parent_forename"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_forename", val)}
      />

      <DynamicField
        name="parent_surname"
        label="Parent/guardian last name"
        value={membership["parent_surname"]}
        error={errors["parent_surname"] ? errors["parent_surname"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_surname", val)}
      />

      <DynamicField
        name="parent_email"
        label="Parent/guardian email"
        value={membership["parent_email"]}
        error={errors["parent_email"] ? errors["parent_email"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_email", val)}
      />

      <DynamicField
        name="parent_mobile"
        label="Parent/guardian mobile number"
        value={membership["parent_mobile"]}
        error={errors["parent_mobile"] ? errors["parent_mobile"][0] : ""}
        isRequired={true}
        handleOnChange={(val) => handleValueChange("parent_mobile", val)}
      />
      {(membershipProduct.constraints["require_parent_2"] === "1" ||
        membershipProduct.constraints["mandatory_parent_2"] === "1") && (
        <div>
          <DynamicField
            name="parent_2_forename"
            label="2nd Parent/guardian first name"
            value={membership["custom_data"]["parent_2_forename"] || ""}
            error={
              errors["parent_2_forename"] ? errors["parent_2_forename"][0] : ""
            }
            isRequired={
              membershipProduct.constraints["mandatory_parent_2"] === "1"
            }
            handleOnChange={(val) =>
              handleCustomValueChange("parent_2_forename", val)
            }
          />

          <DynamicField
            name="parent_2_surname"
            label="2nd Parent/guardian last name"
            value={membership["custom_data"]["parent_2_surname"] || ""}
            error={
              errors["parent_2_surname"] ? errors["parent_2_surname"][0] : ""
            }
            isRequired={
              membershipProduct.constraints["mandatory_parent_2"] === "1"
            }
            handleOnChange={(val) =>
              handleCustomValueChange("parent_2_surname", val)
            }
          />

          <DynamicField
            name="parent_2_email"
            label="2nd Parent/guardian email"
            value={membership["custom_data"]["parent_2_email"] || ""}
            error={errors["parent_2_email"] ? errors["parent_2_email"][0] : ""}
            isRequired={
              membershipProduct.constraints["mandatory_parent_2"] === "1"
            }
            handleOnChange={(val) =>
              handleCustomValueChange("parent_2_email", val)
            }
          />

          <DynamicField
            name="parent_2_mobile"
            label="2nd Parent/guardian mobile number"
            value={membership["custom_data"]["parent_2_mobile"] || ""}
            error={
              errors["parent_2_mobile"] ? errors["parent_2_mobile"][0] : ""
            }
            isRequired={
              membershipProduct.constraints["mandatory_parent_2"] === "1"
            }
            handleOnChange={(val) =>
              handleCustomValueChange("parent_2_mobile", val)
            }
          />
        </div>
      )}
    </Fieldset>
  );
};

export default Parent;
