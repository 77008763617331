import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Fieldset from "lib/components/Fieldset/Fieldset";
import {DynamicField} from "./FormInputs";
import AdditionalInformation from "./AdditionalInformation";

export default class MemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      focused: false,
      value: ""
    };
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  handleValueChange(val, field) {
    this.props.handleValueChange(this.props.index, field, val);
  }

  render() {
    const {
      index,
      isAdminForm,
      member,
      membership,
      memberTypes,
      membershipProduct,
      handleValueChange,
      handleMemberTypeChange,
      handleCustomValueChange,
      handleAccessorValueChange,
      errors,
      genderOptions,
      codes,
    } = this.props;
    return (
      <div className="member_fields">
        <Fieldset
          legend={`${membershipProduct.club_id === 2323 ? "Child" : "Member"} ${
            index + 1
          } Details - ${
            member.entity == null ? "Pick a Member Type" : member.entity.name
          }`}
          description={
            member.entity == null ? "" : member.entity.membership_description
          }
        >
          {(membership.unconfirmed || isAdminForm) && (<DynamicField
            type="select"
            collection={memberTypes}
            name={`entity_type_${index}`}
            label={`${
              membershipProduct.club_id === 2323 ? "Child" : "Member"
            } Type`}
            value={member.entity == null ? 0 : member.entity.id}
            error={errors["member_entity"] ? errors["member_entity"][0] : ""}
            isRequired={true}
            handleOnChange={(value) =>
              handleMemberTypeChange(index, parseInt(value))
            }
          />)}

          <DynamicField
            name={`forename_${index}`}
            label="Player/member first name"
            value={member["forename"]}
            error={errors["forename"] ? errors["forename"][0] : ""}
            isRequired={true}
            readOnly={member.readonly_identity && !isAdminForm}
            handleOnChange={(val) => this.handleValueChange(val, "forename")}
          />

          <DynamicField
            name={`surname_${index}`}
            label="Player/member last name"
            value={member["surname"]}
            error={errors["surname"] ? errors["surname"][0] : ""}
            isRequired={true}
            readOnly={member.readonly_identity && !isAdminForm}
            handleOnChange={(val) => this.handleValueChange(val, "surname")}
          />

          {member.entity && member.entity.constraints.require_email === "1" && (
            <DynamicField
              type="email"
              name={`email_${index}`}
              label="Email address"
              value={member["email"]}
              error={errors["email"] ? errors["email"][0] : ""}
              isRequired={true}
              placeholder="joe.bloggs@example.org"
              handleOnChange={(val) => this.handleValueChange(val, "email")}
            />
          )}

          {member.entity &&
            member.entity.constraints.require_phone_number === "1" && (
              <DynamicField
                name={`phone_number_${index}`}
                label="Phone number (including country & area codes)"
                value={member["phone_number"]}
                error={errors["phone_number"] ? errors["phone_number"][0] : ""}
                isRequired={true}
                placeholder="+353 87 1234567"
                handleOnChange={(val) =>
                  this.handleValueChange(val, "phone_number")
                }
              />
            )}

          <DynamicField
            type="select"
            collection={genderOptions}
            name={`gender_${index}`}
            label="Gender"
            value={member["gender"]?.toString()}
            error={errors["gender"] ? errors["gender"][0] : ""}
            isRequired={true}
            readOnly={member.readonly_identity}
            handleOnChange={(val) => handleValueChange(index, "gender", val)}
          />

          {member.entity?.require_codes && codes.length > 1 && (
            <DynamicField
              type="multi_select"
              collection={codes}
              name={`code_ids_${index}`}
              label="Sports this member actively plays, coaches, administers or follows"
              value={member["code_ids"]}
              error={errors["code_ids"] ? errors["code_ids"][0] : ""}
              isRequired={true}
              handleOnChange={(val) =>
                handleValueChange(index, "code_ids", val)
              }
            />
          )}

          {member.entity && member.entity.dob && (
            <DynamicField
              type="dob"
              required={!member["entity"]["constraints"]["optional_dob"]}
              memberIndex={index}
              date={member["dob"] ? moment(member["dob"]) : member["dob"]}
              error={errors["dob"] ? errors["dob"][0] : ""}
              setDate={(date) =>
                handleValueChange(
                  index,
                  "dob",
                  date ? date.format("YYYY-MM-DD") : null
                )
              }
            />
          )}
        </Fieldset>

        {member.entity && member.entity.parental_consent && (
          <Fieldset
            legend="Policies"
            description={`Required validations and policy acceptance for ${member.entity.name} membership.`}
          >
            <DynamicField
              type="accept"
              name={`parental_consent_${index}`}
              label="Accept Parental Consent?"
              value={
                typeof member["parental_consent"] === "string"
                  ? member["parental_consent"]
                  : (!!member["parental_consent"]).toString()
              }
              policy={membershipProduct.consent_disclaimer_s}
              policyTitle="Parental Consent Policy"
              policyLink="View Policy"
              isRequired={true}
              optionLeft="false"
              optionRight="true"
              labelLeft="No"
              labelRight="Yes"
              error={
                errors["parental_consent"] ? errors["parental_consent"][0] : ""
              }
              handleOnChange={(value) =>
                handleValueChange(index, "parental_consent", value)
              }
            />
          </Fieldset>
        )}

        {member.entity &&
          member.entity.emergency_contact && (
            <EmergencyDetails
              index={index}
              member={member}
              handleValueChange={handleValueChange}
              errors={errors}
            />
          )}

        {member.entity && member.entity.medical_disclosure && member.entity.legacy_medical_disclosure && (
          <LegacyMedicalDisclosure
            index={index}
            member={member}
            handleValueChange={handleValueChange}
            errors={errors}
          />
        )}

        {member.entity && member.entity.medical_disclosure && !member.entity.legacy_medical_disclosure && (
          <MedicalDisclosure
            index={index}
            member={member}
            handleValueChange={handleValueChange}
            errors={errors}
          />
        )}

        {member.entity && member.entity.photo_consent && !member.entity.legacy_photo_consent && (
          <PhotoConsent
            index={index}
            member={member}
            handleValueChange={handleValueChange}
            errors={errors}
          />
        )}

        {(member.entity?.custom_fields?.length || 0 > 0) && (
          <AdditionalInformation
            index={index}
            customFields={member.entity.custom_fields}
            customData={member["custom_data"]}
            handleValueChange={handleCustomValueChange}
            errors={this.props.errors}
          />
        )}

        <div className="member_remove">
          <a
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              this.props.handleRemoveMember(index, member);
            }}
          >
            {`Remove ${membershipProduct.club_id != 2323 ? "member" : "child"}`}
          </a>
        </div>
      </div>
    );
  }
}

MemberForm.propTypes = {
  member: PropTypes.object.isRequired,
  membershipProduct: PropTypes.object.isRequired
};

const EmergencyDetails = ({index, member, handleValueChange, errors}) => (
  <Fieldset
    legend={"Emergency Contact"}
    description="Please provide details of someone to contact on behalf of this member in case of emergency."
  >
    <div>
      <DynamicField
        name={`emergency_name_${index}`}
        label="Emergency contact name."
        value={member["emergency_name"] || ""}
        isRequired={true}
        error={errors["emergency_name"] ? errors["emergency_name"][0] : ""}
        handleOnChange={val =>
          handleValueChange(index, "emergency_name", val)
        }
      />
      <DynamicField
        name={`emergency_number_${index}`}
        label="Emergency contact phone number."
        value={member["emergency_number"] || ""}
        isRequired={true}
        error={
          errors["emergency_number"] ? errors["emergency_number"][0] : ""
        }
        handleOnChange={val =>
          handleValueChange(index, "emergency_number", val)
        }
      />
    </div>
  </Fieldset>
);

const LegacyMedicalDisclosure = ({index, member, handleValueChange, errors}) => (
  <Fieldset
    legend={"Medical Disclosure"}
    description="Please outline any medical information (i.e. allergies, conditions, medication) which may impact on this person's health, welfare, learning or behaviour while participating in our activities."
  >
    <DynamicField
      name={`medical_conditions_${index}`}
      label="Medical Details/Requirements (or just enter 'None')"
      value={member["medical_conditions"] || ""}
      isRequired={true}
      error={
        errors["medical_conditions"] ? errors["medical_conditions"][0] : ""
      }
      handleOnChange={val =>
        handleValueChange(index, "medical_conditions", val)
      }
    />
  </Fieldset>
);

const MedicalDisclosure = ({index, member, handleValueChange, errors}) => (
  <Fieldset
    legend={"Medical Disclosure"}
    description="Please indicate whether this member has allergies, conditions or medication requirements, which may impact their health, welfare, learning or behaviour while
        participating in our activities."
  >
    <DynamicField
      type="consent"
      name={`medical_disclosure_${index}`}
      label="Allergies, conditions or medication requirements?"
      description="If answering Yes, then please remember to provide the coach with more details before this member
      participates in activities."
      value={member["medical_disclosure"]}
      isRequired={true}
      optionLeft="0"
      optionRight="1"
      error={
        errors["medical_disclosure"] ? errors["medical_disclosure"][0] : ""
      }
      handleOnChange={val =>
        handleValueChange(index, "medical_disclosure", val)
      }
    />
  </Fieldset>
);

const PhotoConsent = ({index, member, handleValueChange, errors}) => (
  <Fieldset
    legend={"Photo Consent"}
    // description="At times the club may wish to take photos or videos of teams or individuals solely for promotion and celebration of the activities of the club and for training. I give consent for this member's image to be used for this purpose."
    description="I am aware that this member's photograph or video image may be taken whilst attending or participating in activities connected with the Club and I consent to it being used by the Club for items like programmes, year books, event reports or on the Club website or social media channels."
  >
    <DynamicField
      type="consent"
      name={`photo_consent_${index}`}
      label="Do you consent to photographs or video images of this member being used for these purposes?"
      value={member["photo_consent"]}
      isRequired={true}
      optionLeft="0"
      optionRight="1"
      error={
        errors["photo_consent"] ? errors["photo_consent"][0] : ""
      }
      handleOnChange={val =>
        handleValueChange(index, "photo_consent", val)
      }
    />
  </Fieldset>
);


const dupePropTypes = {
  index: PropTypes.number.isRequired,
  isAdminForm: PropTypes.bool,
  member: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

EmergencyDetails.propTypes = dupePropTypes;
