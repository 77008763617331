import React        from 'react';
import styles       from './YesNo.module.scss';
import RadioButton from "../RadioButton/RadioButton";

let YesNo = ({ parentClassName, handleOnChange, name, value, optionLeft, optionRight }) => (
  <div className={styles.yes_no_container} onChange={(event) => handleOnChange(event.target.value)}>
    <span className={styles.label}>No</span>
    <RadioButton
      name={name}
      value={optionLeft}
      defaultChecked={value === optionLeft || value === false || value === '0'}
    />
    <RadioButton
      name={name}
      value={optionRight}
      defaultChecked={value === optionRight || value === true || value === '1'}
    />
    <span className={styles.label}>Yes</span>
  </div>
)

export default YesNo;
