import React from "react";
import PropTypes from "prop-types";

import Fieldset from "lib/components/Fieldset/Fieldset";

import { DynamicField } from "../FormInputs";

const Receipt = ({ membership, handlePayeeChange, errors }) => {
  const payeeIndex = membership.members.findIndex((mem) => mem.is_payee);
  return (
    <Fieldset legend="Receipt" description="Who should we send the receipt to?">
      <DynamicField
        type="select"
        collection={membership.members
          .map((mem, index) => [index + 1, mem])
          .filter(
            (mem) =>
              !["Child", "Youth"].includes(mem[1].entity?.membership_type) &&
              mem[1].email &&
              mem[1].email.length > 0
          )
          .map((mem) => [
            mem[0],
            `${mem[1].forename} ${mem[1].surname} (${mem[1].email})`,
          ])}
        id="payee_id"
        name="payee_id"
        label="Select who is paying"
        value={payeeIndex + 1}
        error={errors.length > 0 ? errors[0] : ""}
        isRequired={true}
        handleOnChange={(val) => handlePayeeChange(parseInt(val) - 1)}
      />
    </Fieldset>
  );
};

Receipt.propTypes = {
  membership: PropTypes.object.isRequired,
};

export default Receipt;
