import { Menu, Transition } from "@headlessui/react";
import { classNames } from "./utils";
import {Fragment} from "react";
import PropTypes from "prop-types";

const MembershipOptions = ({
  options
}) => {
  let Icon = ({icon, color}) => (
    <div className="inline-block w-8 content-center">
      <i className={classNames(color, icon, "fa")}></i>
    </div>
  );

  let Link = ({item, active}) => (
    <a
      href={item.url || '#'}
      data-method={item.method}
      data-confirm={item.confirm}
      data-session-type={item.sessionType}
      data-resource-type={item.resourceType}
      data-resource-id={item.resourceId}
      data-resource-version={item.resourceVersion}
      className={classNames(
        item.color,
        active ? "text-gray-700" : "",
        "text-left no-underline px-2 py-2 w-full"
      )}
    >
      {item.name}
    </a>
  );

  return (
    <Menu as="div" className="z-50 relative sm:inline-block">
      {({open}) => (
        <div>
          <div>
            <Menu.Button className="btn btn-primary">
              Options&nbsp;
              <i className="fa fa-chevron-down"/>
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute left-1/2 -translate-x-1/2 mt-3 p-1 md:p-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 text-left"
            >
              <div className="py-1 grid grid-cols-1" >
                {options.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? "bg-gray-100 text-gray-700" : "",
                          "text-red rounded-md py-2 px-2"
                        )}
                      >
                        <Icon icon={item.icon} color={item.color} />
                        <Link item={item} active={active}/>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

MembershipOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MembershipOptions;
