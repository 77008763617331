import React        from 'react';
import styles       from './RadioButton.module.scss';

let RadioButton = ({ parentClassName, ...rest }) => (
  <div className={[styles.radiobutton, parentClassName || ''].join(' ')}>
    <input {...rest} type="radio"/>
    <span></span>
  </div>
)

export default RadioButton;
